import React from 'react';
import Navbar from '../Components/Navs/Navbar';
import Hero from '../Components/Home/hero';
import Contact from '../Components/Home/home-contact';
import EmpowerCareerJourney from '../Components/Home/empower-career-journey';
import RefineTalent from '../Components/Home/refine-talent';
import Footer from '../Components/Navs/footer';

function App() {
    return (
        <>
            <Navbar />
            <Hero />
            <Contact />
            <EmpowerCareerJourney />
            <RefineTalent />
            <Footer />
        </>
    );
}

export default App;
