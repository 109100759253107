import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import logger from '../../utils/logger';
const Contact = () => {
    const [inputValue, setInputValue] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [verified, setVerified] = useState(false); // State for verification

    const handleInputChange = (event) => {
        const sanitizedValue = DOMPurify.sanitize(event.target.value);
        setInputValue(sanitizedValue);
    };

    const handleVerify = () => {
        // This function should contain the logic to verify the user's input
        // For demonstration, we'll set verified to true when called
        setVerified(true);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (verified) {
            // Validate email format
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailPattern.test(inputValue)) {
                logger.info('Form submitted with email:', inputValue);
            } else {
                alert('Please enter a valid email address.');
            }
        } else {
            alert('Please complete the CAPTCHA.');
        }
    };

    return (
        <div id="contact" className="contact-page-wrapper sm:my-4 mx-4 md:mx-0 relative z-0">
            <div className="h-[213px] flex-col justify-start items-center gap-10 inline-flex">
                <div className="sm:my-4 md:w-[680px] text-center">
                    <span className="text-2xl font-bold text-gray-800">
                        Lillup combines advanced technology with personalised experiences to bridge
                        the gap between education and employment.
                        <br />
                        <br />
                    </span>
                    <span className="text-lg font-semibold text-gray-800">
                        Join the revolution in career development and talent acquisition:
                    </span>
                </div>
                <form
                    onSubmit={handleFormSubmit}
                    className="flex flex-col md:flex-row items-center gap-5">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
                        <input
                            className="w-full sm:w-auto px-4 py-2 bg-neutral-100 rounded-md text-base font-sans"
                            type="email"
                            placeholder={
                                windowWidth < 768 ? 'Your Email' : 'Enter your email address'
                            }
                            style={{ backgroundColor: '#f2f2f2', color: '#333' }}
                            value={inputValue}
                            onChange={handleInputChange}
                            aria-label="Email address" // Accessible label
                        />
                    </div>
                    <div className="w-auto bg-red-600 rounded-md flex justify-center items-center">
                        <button type="submit" className="primary-button text-white py-2 px-4">
                            Subscribe
                        </button>
                    </div>
                </form>
                {/* Verification button for demonstration */}
                <button
                    onClick={handleVerify}
                    className="bg-500 text-white mt-6 px-3 py-1 rounded-[10px]">
                    Complete CAPTCHA
                </button>
            </div>
        </div>
    );
};

export default Contact;
