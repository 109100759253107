import React from 'react';
import '../../index.css';
import backgroundImg from '../../assets/images/image3.png';
import LinkedinImage from '../../assets/images/linkedin.png';
import TwitterXImage from '../../assets/images/twitterx.png';
import instagramImage from '../../assets/images/instagram.png';

const Footer = () => {
    const backgroundImageStyle = {
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    };
    return (
        <div
            className="flex flex-col md:flex-row md:items-center lg:p-8 pt-24"
            style={backgroundImageStyle}>
            <div className="hidden md:block flex-shrink-0 lg:w-3/6 lg:flex space-x-4">
                <span>© 2024 Lillup. All rights reserved.</span>
            </div>

            <div className="flex flex-col md:w-2/5 md:flex-row md:space-x-4 md:flex-shrink-0">
                <div className="flex flex-col p-10 py-0 gap-[25px] md:flex-row md:gap-[15px] text-sm font-bold leading-6 text-left">
                    <span className="">
                        <a href="#footer">Terms and Conditions</a>
                    </span>
                    <span className="">
                        <a href="#footer">Privacy Policy</a>
                    </span>
                    <span className="">
                        <a href="#footer">Contact</a>
                    </span>
                </div>
            </div>

            <div className=" flex  justify-between p-10 md:pl-0 md:flex-shrink-0 md:space-x-4 md:ml-auto">
                <a
                    href="https://twitter.com/LillupWorkplace"
                    target="__blank"
                    className="duration-500 ease-in-out hover:scale-110 hover:transition-all w-8">
                    <img src={TwitterXImage} alt="twitter icon" />
                </a>
                <a
                    href="https://www.linkedin.com/company/lillup/"
                    target="__blank"
                    className="duration-500 ease-in-out hover:scale-110 hover:transition-all w-8">
                    <img src={LinkedinImage} alt="linkedin icon" />
                </a>
                <a
                    href="https://www.instagram.com/lillup_ampersand/"
                    target="__blank"
                    className="duration-500 ease-in-out hover:scale-110 hover:transition-all w-8">
                    <img src={instagramImage} alt="instagram icon" />
                </a>
            </div>
            <div className="sm:block md:hidden flex-shrink-0 pl-10 pb-10   w-auto">
                <span>© 2024 Lillup. All rights reserved.</span>
            </div>
        </div>
    );
};

export default Footer;
