export const NavbarMenu = [
    {
        id: 1,
        title: 'For Individuals',
        link: '#'
    },
    {
        id: 2,
        title: 'For Companies',
        link: '#'
    }
];
